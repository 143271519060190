import React from 'react';
import { graphql } from 'gatsby';
import DemoBanner from '../../components/DemoBanner';
import DeviceHero from '../../components/DeviceHero';
import DeviceSection from '../../components/DeviceSection';
import Layout from '../../components/Layout';
import ResourcesSection from '../../components/ResourcesSection';
import Wayfinding from '../../components/Wayfinding';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import SiteMeta from '../../components/SiteMeta';

const LearnPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroModule,
    learnIntroductionModule,
    learnInfoModule1,
    learnInfoModule2,
    learnInfoModule2b,
    learnInfoModule3a,
    learnInfoModule3b,
    demoCallout,
    wayfindingCardTitle,
    wayfindingCardSubheader: { wayfindingCardSubheader },
    wayfindingCards,
    featuredResources,
    featuredResourcesTitle,
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText },
  } = data.contentfulLearnPage;


  const heroDeviceData = deviceDataFromDeviceAsset(heroModule.deviceAsset);

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  const wayfindingData = {
    title: wayfindingCardTitle,
    subtitle: wayfindingCardSubheader,
    items: wayfindingCards.map(
      ({ wayfindingCardTitle, wayfindingCardBody, matchUrl }) => {
        return {
          title: wayfindingCardTitle,
          body: wayfindingCardBody.wayfindingCardBody,
          matchUrl,
        };
      },
    ),
    currentPath: location.pathname,
  };


  const deviceSections = [
    {
      ...learnIntroductionModule,
      barTheme: 'transparent'
    },
    {
      ...learnInfoModule1,
    },
    learnInfoModule2,
    learnInfoModule2b,
    {
      ...learnInfoModule3a,
      barTheme: 'dark-grey'
    },
    {
      ...learnInfoModule3b,
      barTheme: 'transparent'
    }
  ]

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />

      <DeviceHero
        title={heroModule.title}
        body={heroModule.body}
        {...heroDeviceData}
      />

      {deviceSections.map((section, index) => {
        const layout = !(index % 2) ? 'deviceRight' : 'deviceLeft';
        const deviceData = deviceDataFromDeviceAsset(section.deviceAsset)
        return (
          <DeviceSection {...section} {...deviceData} key={index} layoutVariant={layout} />
        );
      })}
      {/* <Wayfinding {...wayfindingData} /> */}
      <DemoBanner {...demoCallout} theme="dark" />
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default LearnPage;

export const query = graphql`
  query {
    contentfulLearnPage{
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...DeviceHero
      }
      learnIntroductionModule{
        ...DeviceNavigator
      }
      learnInfoModule1 {
        ...DeviceNavigator
      }
      learnInfoModule2 {
        ...DeviceNavigator
      }
      learnInfoModule2b {
        ...DeviceNavigator
      }
      learnInfoModule3a {
        ...DeviceNavigator
      }
      learnInfoModule3b {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      wayfindingCardTitle
      wayfindingCardSubheader {
        wayfindingCardSubheader
      }
      wayfindingCards {
        ...WayfindingCard
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
    }
  }
`;
